<template>
    <div class="o365-rich-textarea position-relative" style="overflow-y: auto;">

        <template v-if="control.editMode || editMode">
            <slot name="toolbar" v-if="!hideToolbar">
                <div class="d-flex">
                    <!-- <button class="btn btn-sm btn-link" @click="control.addHeading()" :title="$t('Add heading text')"><i class="bi bi-type-h3"></i></button> -->
                    <button class="btn btn-sm btn-link" 
                        @click="control.addBoldText()" 
                        v-tooltip="$t('Add bold text, <Ctrl + b>')">
                        <i class="bi bi-type-bold"></i>
                    </button>
                    <button class="btn btn-sm btn-link" 
                        @click="control.addItalicText()" 
                        v-tooltip="$t('Add italic text, <Ctrl + i>')">
                        <i class="bi bi-type-italic"></i>
                    </button>
                    <!--<button class="btn btn-sm btn-link" @click="control.addQuate()" :title="$t('Add a quate')"><i class="bi bi-blockquote-left"></i></button> -->
                    <button class="btn btn-sm btn-link" 
                        @click="control.addCode()" 
                        v-tooltip="$t('Add code, <Ctrl + e>')">
                        <i class="bi bi-code"></i>
                    </button>
                    <button class="btn btn-sm btn-link" 
                        @click="control.addLink()" 
                        v-tooltip="$t('Add a link, <Ctrl + k>')">
                        <i class="bi bi-link-45deg"></i>
                    </button>
                    <button class="btn btn-sm btn-link" 
                        @click="control.addBulletedList()" 
                        v-tooltip="$t('Add a bulleted list')">
                        <i class="bi bi-list-ul"></i>
                    </button>
                    <OFileUploadButton v-if="attachmentsDataObject && !hideAttachmentsUploadBtn" :dataObject="attachmentsDataObject"
                        @uploaded="data => control.handleAttachmentUploaded(data)" hideProgressBar>
                        <button class="btn btn-sm btn-link" v-tooltip="$t('Upload an attachment')"
                            @click="() => control.cancelEditorCloseFromBlur()">
                            <i class="bi bi-paperclip"></i>
                        </button>
                    </OFileUploadButton>
                    <button v-if="!hideAttachmentsList" class="btn btn-sm btn-link" :class="{ 'ms-auto': !hideAttachmentsList}" 
                        @click="() => {control.cancelEditorCloseFromBlur(); $refs.attachmentsList?.showDialog()}" 
                        v-tooltip="$t('Open attachments list')">
                        <i class="bi bi-files"></i>
                    </button>
                    <a class="btn btn-sm btn-link" :class="{ 'ms-auto': hideAttachmentsList}" 
                        href="https://docs.github.com/en/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax" 
                        target="_blank"
                        v-tooltip="$t('Styling with Markdown is supported')">
                        <i class="bi bi-markdown"></i>
                    </a>
                </div>
            </slot>
            <OTextArea v-model="internalModel" :disabled="control.isUploading"
                @vue:mounted="onTextAreaMounted" @vue:beforeUnmount="onTextAreaBeforeUnmount" 
                :rows="rows" :class="textAreaClass" 
                @blur="e =>{ $emit('blur', e); control.handleEditorBlur(e)}" 
                @focus="control.handleEditorFocus($event)" :ref="(cmp) => {control.setupTextArea(cmp)}" 
                v-bind="textAreaOptions"/>
        </template>
        <template v-else>
            <MarkedPreview :class="previewClass" @dblclick="control.handlePreviewClick($event)" :value="modelValue" :style="{'min-height': 28*rows+6+'px'}" :markedOptions="{ breaks: true}"/>
            <div class="d-flex position-sticky bottom-0 start-0 w-100" id="richTextEditBtnWrapper">
                <button id="richTextEditBtn" class="btn btn-sm btn-link ms-auto" @click="control.handlePreviewClick($event)">{{$t('Edit')}}</button>
            </div>
        </template>

        <ODialog v-if="!hideAttachmentsList && attachmentsDataObject" ref="attachmentsList" size="lg" :title="$t('Attachments')" @show="() => attachmentsDataObject.load()"
            backdrop @hidden="() => control.focus()">
            <div class="o365-dialog-body d-flex" style="padding: 0px!important;">
                <ODataGrid :dataObject="attachmentsDataObject" hideNewRecords hideGridMenu>
                    <OColumn field="PrimKey" hide/>
                    <OColumn field="FileName" flexWidth="2"/>
                    <OColumn field="FileRef" flexWidth="1"/>
                </ODataGrid>
            </div>
        </ODialog>
    </div>
</template>

<script lang="ts">
export interface IRichTextAreaProps {
    modelValue: string | null,
    textAreaClass?: string,
    previewClass?: string,
    rows?: number|string,
    editMode?: boolean,
    hideToolbar?: boolean
    hideEditButton?: boolean;
    textAreaOptions?: any,
    attachmentsDataObject?: DataObject,
    hideAttachmentsList?: boolean,
    hideAttachmentsUploadBtn?: boolean,
}
</script>

<script setup lang="ts">
import type { DataObject } from 'o365-dataobject';
import { ODataGrid, OColumn } from 'o365-datagrid';
import { OTextArea, ODialog } from 'o365-ui-components';
import { vTooltip, useAsyncComponent } from 'o365-vue-utils';
import RichTextAreaControl from 'o365.controls.RichTextAreaControl.ts';
import MarkedPreview from 'o365.vue.components.Marked.vue';
import { computed, reactive } from 'vue';

const OFileUploadButton = useAsyncComponent('o365-fileupload/OFileUploadButton', {
    importFn: () => import('o365-fileupload').then(m => m.OFileUploadButton)
});

const props = withDefaults(defineProps<IRichTextAreaProps>(), {
    textAreaClass: 'form-control form-control-sm',
    textAreaOptions: {
        noResize: true,
        autoGrow: true,
    },
    rows: 3
});




const emit = defineEmits<{
    (e: 'update:modelValue', value: string|null): void,
    (e: 'blur', event: Event): void
}>();

const internalModel = computed({
    get() { return props.modelValue ?? ''; },
    set(value) { 
        if( !control.editMode) { control.editMode = true };
        emit('update:modelValue', value);
    }
});

const control = reactive(new RichTextAreaControl({
    props: props,
    getModel: () => props.modelValue??'',
    setModel: (value: string) => { 
        if( !control.editMode) { control.editMode = true };
        emit('update:modelValue', value);
    },
}));

let textAreaScroll = 0;
function onTextAreaMounted(cmp) {
    window.requestAnimationFrame(() => {
        if (cmp?.el) { cmp.el.scrollTop = textAreaScroll; }
    });
}

function onTextAreaBeforeUnmount(cmp) {
    if (cmp?.el) { textAreaScroll = cmp.el.scrollTop; }
}

defineExpose({ control })
</script>

<style scoped>
.o365-rich-textarea .o365-rich-textarea-edit {
    display: none;
}
.o365-rich-textarea:hover .o365-rich-textarea-edit {
    display: block;
}

#richTextEditBtnWrapper {
    background-color: var(--bs-body-bg);
}

</style>